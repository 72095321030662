import { h, Component } from 'preact';
import { Router } from 'preact-router';

// Code-splitting is automated for routes
import Home from '../routes/home';
import Plans from '../routes/plans';
import FAQs from '../routes/faqs';
import ContactUs from '../routes/contact-us';
import Terms from '../routes/terms';
import Privacy from '../routes/privacy';
import EULA from '../routes/eula';

export default class App extends Component {
	
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		let oldUrl = this.currentUrl;
		this.currentUrl = e.url;
		if (typeof window !== 'undefined' && oldUrl !== this.currentUrl) {
			window.scrollTo(0, 0);
		}
	};

	render() {
		return (
			<div id="app">
				<Router onChange={this.handleRoute}>
					<Home path="/" />
					<Plans path="/plans" />
					<FAQs path="/faqs" />
					<ContactUs path="/contact-us" />
					<Terms path="/terms" />
					<EULA path="/eula" />
					<Privacy path="/privacy" />
				</Router>
			</div>
		);
	}
}
